<template>
  <div class="field">
    <div class="control is-expanded has-icons-right">
      <input
        ref="search"
        v-model="value"
        type="text"
        name="search"
        :class="['input has-text-left', `is-size-${size}`, 'is-size-6-mobile']"
        v-bind="{ placeholder }"
        autocomplete="off"
        data-test-id="table-search"
      />
      <span :class="['icon is-right', `is-size-${size}`, 'is-size-6-mobile']">
        <i class="fal fa-search" />
      </span>
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash'
export default {
  name: 'TableSearch',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: () => 'Start typing to search'
    },
    size: {
      type: String,
      required: false,
      default: () => '6'
    }
  },
  data: () => ({ value: '' }),
  watch: {
    value: 'emitChange'
  },
  mounted() {
    this.value = this.$route.query?.query || ''
  },
  methods: {
    emitChange() {
      debounce(() => {
        if (this.value) {
          return this.$emit('change', this.value)
        } else {
          return this.$emit('change')
        }
      }, 500)()
    }
  }
}
</script>
